// Checks if the user is connected to the internal VPN
import { getData } from '@/api';

export default async function vpnGuard(to, from, next, store) {
    const routeIsInternal = to.matched.some((route) => route.meta.isInternal);
    store.commit('auth/setIsInternalRoute', routeIsInternal);

    const response = await getData('/api/auth/check-internal-ip').catch((error) => {
        next('403');
        return false;
    });

    store.commit('auth/setIsOnVpn', response.isInternal);

    if (!response.isInternal && routeIsInternal) {
        next('403');
        return false;
    }

    return true;
}
