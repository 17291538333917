<template>
    <Dialog :visible="visible" @show="onDialogShow" @hide="$emit('update:visible', false)" :header="header" modal style="width: 400px">
        <div class="flex flex-column align-items-center p-3">
            <p v-if="verificationMethod === 'sms'">Please enter the code you received via SMS.</p>
            <p v-else>Please enter the code from your authenticator app.</p>

            <Message v-if="errorMessage" :severity="'error'" class="w-full mb-3" :closable="true" @close="errorMessage = ''">
                {{ errorMessage }}
            </Message>

            <InputText class="w-full mb-3" placeholder="Enter your 2FA code" v-model="twoFactorCode" @keyup.enter="verify" />

            <div class="w-full flex justify-content-between gap-2">
                <Button
                    label="Cancel"
                    class="p-button-text"
                    @click="
                        () => {
                            $emit('update:visible', false);
                            $emit('verificationCancelled');
                        }
                    "
                />
                <Button :loading="isVerifying" label="Verify" class="p-button-primary" @click="verify" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, defineEmits, computed, onMounted } from 'vue';
import { postData } from '@/api';
import { useStore } from 'vuex';

const store = useStore();

const user = computed(() => store.getters['auth/getUser']);

const verificationMethod = computed(() => user.value?.two_factor_provider ?? 'authenticator');

const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    },
    header: {
        type: String,
        default: 'Two Factor Authentication',
        required: false
    }
});

const emit = defineEmits(['verificationSuccessful', 'verificationFailed', 'update:visible', 'verificationCancelled']);

const twoFactorCode = ref('');
const errorMessage = ref('');
const isVerifying = ref(false);

const sendSMSCode = async () => {
    try {
        const phone = user.value?.sms_number ?? false;

        if (!phone) {
            throw new Error('No phone number found for SMS verification.');
        }

        const response = await postData('/api/user/send-sms-code', { phone: phone });
        console.log('SMS code sent successfully:', response);
    } catch (error) {
        console.error('Error sending SMS code:', error);
        errorMessage.value = {
            active: true,
            message: error.response?.data?.message ?? 'An error occurred while sending the SMS code.',
            severity: 'error'
        };
    }
};

const verify = async () => {
    errorMessage.value = '';
    isVerifying.value = true;

    let endpoint = '/api/user/two-factor-challenge';
    let payload = { code: twoFactorCode.value };

    try {
        // Attempt to verify the 2FA code
        await postData(endpoint, payload);

        // If successful, emit a success event
        emit('verificationSuccessful');
        emit('update:visible', false);
    } catch (error) {
        // Emit a failure event
        emit('verificationFailed', error);
        // Display an error message
        errorMessage.value = error.response?.data?.message ?? 'Invalid code. Please try again.';
    } finally {
        isVerifying.value = false;
        twoFactorCode.value = '';
    }
};

const onDialogShow = () => {
    if (verificationMethod.value === 'sms') {
        sendSMSCode();
    }
};
</script>

<style scoped></style>
