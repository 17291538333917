// Checks if the user is logged in and has the correct permissions.
import { refreshUser } from '@/api';

export default async function authGuard(to, from, next, store) {
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
    const requiresAbility = to.matched.some((route) => route.meta.requiresAbility);
    let didRefreshUser = false;

    // if the user is already Authenticated, no need to refresh the user
    if (store.getters['auth/isAuthenticated']) {
        didRefreshUser = true;
    }

    // If a token exists in local storage but not in the store, refresh the users data
    const existingToken = localStorage.getItem('madrivoAuthToken');
    const expiresAt = localStorage.getItem('madrivoAuthTokenExpiresAt');

    if (existingToken && !store.getters['auth/isAuthenticated']) {
        didRefreshUser = await refreshUser();
    }

    if (requiresAuth && !didRefreshUser) {
        next('/login');
        return false;
    }

    if (requiresAuth && didRefreshUser && requiresAbility) {
        const ability = to.meta.requiresAbility;

        if (!store.getters['auth/userCan'](ability)) {
            next('/403');
            return false;
        }
    }

    // if the token expires within 1 hour, check 2fa before navigating
    if (expiresAt && requiresAuth) {
        const diff = new Date(expiresAt) - new Date();
        if (diff < 60 * 60 * 1000) {
            console.log('confirming 2FA');
            store.commit('auth/savePendingRoute', to);
            store.commit('auth/setRequires2FA', true);
            next(false);
            return;
        }
    }

    return true;
}
