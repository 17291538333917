export default [
    {
        path: '/internal/reports',
        meta: { title: 'MyMadrivo | Reports', requiresAbility: 'viewReporting' },
        children: [
            {
                path: '/internal/reports/nps-survey-recipients',
                name: 'npsSurveyRecipients',
                component: () => import('@/views/pages/Reports/npsSurveyRecipients.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'viewNpsSurveyRecipients' }
            },
            {
                path: '/internal/reports/tool-usage',
                name: 'toolUsage',
                component: () => import('@/views/pages/Reports/ToolUsageReport.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'viewToolUsage' }
            },
            {
                path: '/internal/reports/offers-without-clicks',
                name: 'offersWithoutClicks',
                component: () => import('@/views/pages/Reports/offersWithoutClicks.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'isAdmin' }
            }
        ]
    }
];
