<template>
    <TwoFactorVerificationModal
        header="Verify Your Identity to Continue"
        :visible="isVisible"
        @verificationSuccessful="on2FASuccess"
        @verificationFailed="on2FAFailed"
        @update:visible="on2FAModalVisibility"
        @verificationCancelled="handleVerificationCancelled"
    />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getData } from '@/api';

import TwoFactorVerificationModal from '@/components/auth/TwoFactorVerificationModal.vue';

const store = useStore();
const router = useRouter();

// The modal shows when requires2FA === true
const isVisible = computed({
    get: () => store.getters['auth/requires2FA'],
    set: (val) => store.commit('auth/setRequires2FA', val)
});

async function on2FASuccess() {
    store.commit('auth/setRequires2FA', false);
    const pending = store.getters['auth/pendingRoute'];
    if (pending) {
        const response = await getData('/api/auth/user/refresh-token');

        if (response.token_expires_at) {
            await localStorage.setItem('madrivoAuthTokenExpiresAt', response.token_expires_at);
        } else {
            console.error('Error refreshing token:', response);
            store.dispatch('auth/logout');
            router.push({ name: 'login' });
            return;
        }

        store.commit('auth/clearPendingRoute');
        router.push(pending);
    }
}

function on2FAFailed(error) {
    console.error('2FA Verification Failed:', error);

    // TODO :: only allow a certain number of attempts?
}

function on2FAModalVisibility(val) {
    store.commit('auth/setRequires2FA', val);
}

async function handleVerificationCancelled() {
    console.log('cancelled');
    store.commit('auth/setRequires2FA', false);
    store.commit('auth/clearPendingRoute');
    store.dispatch('auth/logout');
    router.push({ name: 'login' });
}
</script>
