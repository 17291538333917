import * as Sentry from '@sentry/vue';

const state = {
    isInternalRoute: false,
    user: { name: '', email: '', role: { abilities: [], id: null }, two_factor_confirmed_at: null, two_factor_provider: null },
    isAuthenticated: false,
    advertiser_id: null,
    redirectPath: null,
    isOnVpn: false,
    requires2FA: false,
    pendingRoute: null
};

const getters = {
    isOnVpn: (state) => state.isOnVpn,
    isInternalUser: (state, getters) => {
        return getters.userCan('isInternalUser');
    },
    isInternalRoute: (state) => state.isInternalRoute,
    userCan: (state) => (abilityName) => {
        if (state.user.role && state.user.role.abilities.some((ability) => ability.name === '*')) {
            return true;
        }

        const roleCan = state?.user?.role && state?.user?.role?.abilities.some((ability) => ability.name === abilityName);
        let overrideCan = false;
        let overrides = [];

        if (state?.user?.metas) {
            overrides = state?.user?.metas.filter((meta) => meta.key === 'ability_overrides');
        }
        if (overrides.length > 0) {
            const abilityOverrides = overrides[0].value;
            overrideCan = JSON.parse(abilityOverrides).some((ability) => ability.name === abilityName);
        }
        return roleCan || overrideCan;
    },
    getUser: (state) => state.user,
    userName: (state) => state.user.name,
    userFirstName: (state) => state.user.name.split(' ')[0],
    isAuthenticated: (state) => state.isAuthenticated,
    getHasTwoFactor: (state) => state.user.two_factor_confirmed_at !== null,
    id: (state) => state.user.id,
    redirectPath: (state) => state.redirectPath,
    requires2FA: (state) => state.requires2FA,
    pendingRoute: (state) => state.pendingRoute
};

const actions = {
    setIsInternalRoute({ commit }, isInternalRoute) {
        commit('setIsInternalRoute', isInternalRoute);
    },
    setIsOnVPN({ commit }, isOnVpn) {
        commit('setIsOnVpn', isOnVpn);
    },
    logout({ commit }) {
        commit('setUser', { name: '', email: '', role: null, two_factor_confirmed_at: null, advertiser_id: null });
        commit('setAuthenticated', false);
        localStorage.removeItem('madrivoAuthToken');
        localStorage.removeItem('madrivoAuthTokenExpiresAt');
    }
};

const mutations = {
    setIsInternalRoute(state, isInternalRoute) {
        state.isInternalRoute = isInternalRoute;
    },
    setIsOnVpn(state, isOnVpn) {
        state.isOnVpn = isOnVpn;
    },
    setUser(state, user) {
        state.user = user;
        Sentry.setUser({ id: user.id, email: user.email });
    },
    setAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    setRedirectPath(state, path) {
        state.redirectPath = path;
    },
    setRequires2FA(state, requires2FA) {
        state.requires2FA = requires2FA;
    },
    savePendingRoute(state, route) {
        state.pendingRoute = route;
    },
    clearPendingRoute(state) {
        state.pendingRoute = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
