import axios from 'axios';
import store from '@/store';
import router from '@/router';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
const API_KEY = import.meta.env.VITE_MADRIVO_API_KEY;
let bearerToken = localStorage.getItem('madrivoAuthToken');

export const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'X-Mad-API-Key': API_KEY,
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(
    (config) => {
        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        const isInternal = store.getters['auth/isInternalRoute'];
        config.headers['X-Mad-Is-Internal-Request'] = isInternal ? 'true' : 'false';

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    // On success
    (response) => {
        const refreshToken = response.headers['x-mad-refresh-token'];
        if (refreshToken && refreshToken == 'true') {
            store.commit('auth/setRequires2FA', true);
        }
        return response;
    },
    // On Error
    (error) => {
        return Promise.reject(error);
    }
);

// GET request
export const getData = async (endpoint, data) => {
    let allData = [];
    let nextPageUrl = endpoint;

    while (nextPageUrl) {
        try {
            const response = await apiClient.get(nextPageUrl, { params: data });

            if (typeof response.data === 'object' && 'next_page_url' in response.data) {
                nextPageUrl = response.data.next_page_url;
            } else {
                return response.data;
            }

            nextPageUrl = response.data.next_page_url;
            allData = allData.concat(response?.data?.data ?? response.data);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    return allData;
};

// POST request
export const postData = (endpoint, data) => {
    store.commit('form/clearErrors');
    return apiClient
        .post(endpoint, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response && error.response.status === 422) {
                store.commit('form/setErrors', error.response.data.errors);
            }
            console.error(error);
            throw error;
        });
};

export const postDataStreamedResponse = async (endpoint, data, partialResponseHandler) => {
    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearerToken}`,
                'X-Mad-API-Key': API_KEY
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            console.error('Network response was not ok');
            return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let partialData = '';

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value);
            partialData += chunk;

            let lines = partialData.split('\n\n');
            partialData = lines.pop();

            for (let line of lines) {
                if (line.startsWith('data: ')) {
                    const data = line.substring('data: '.length);
                    if (data === '[DONE]') continue;

                    try {
                        const jsonData = JSON.parse(data);
                        partialResponseHandler(jsonData); // Pass each parsed part to the callback function
                    } catch (e) {
                        console.error('Failed to parse JSON', e);
                    }
                }
            }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
};

// PUT request
export const putData = (endpoint, data) => {
    return apiClient
        .put(endpoint, data)
        .then((response) => response.data)
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

// PATCH request
export const patchData = (endpoint, data) => {
    return apiClient
        .patch(endpoint, data)
        .then((response) => response.data)
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

// DELETE request
export const deleteData = (endpoint, data) => {
    return apiClient
        .delete(endpoint, { data })
        .then((response) => response.data)
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

export const updateToken = (token, expiresAt = null) => {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('madrivoAuthToken', token);
    if (expiresAt) {
        localStorage.setItem('madrivoAuthTokenExpiresAt', expiresAt);
    }
    bearerToken = token;
    return true;
};

export const refreshUser = async () => {
    const existingToken = localStorage.getItem('madrivoAuthToken');
    if (existingToken) {
        await updateToken(existingToken);

        return await getData('/api/auth/user')
            .then(async (response) => {
                await store.commit('auth/setUser', response);
                await store.commit('auth/setAuthenticated', true);
                return true;
            })
            .catch((error) => {
                console.error(error);
                return false;
            });
    }

    return false;
};
